<template>

</template>

<script>
export default {
  name: "Start"
}
</script>

<style scoped>

</style>
